@import '@angular/material/theming';
@include mat-core();

$mat-hospitaler: (
  50: #dff5f6,
  100: #b1e5e8,
  200: #82d4da,
  300: #53c2cc,
  400: #32b7c3,
  500: #17abbb,
  600: #179ca9,
  700: #168790,
  800: #13747a,
  900: #0e5151,
  A100: #b1e5e8,
  A200: #82d4da,
  A400: #32b7c3,
  A700: #168790,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);

$mat-hospitaler-secondary: (
  50: #f2f8ea,
  100: #dfeeca,
  200: #c9e3a8,
  300: #b4d885,
  400: #a3cf69,
  500: #93c74f,
  600: #84b747,
  700: #70a23c,
  800: #5c8e33,
  900: #3a6c22,
  A100: #dfeeca,
  A200: #c9e3a8,
  A400: #a3cf69,
  A700: #70a23c,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $dark-primary-text,
    A400: $dark-primary-text,
    A700: $light-primary-text,
  )
);



// define the color palettes for the theme; palettes are available in palettes.scss
// $hospitaler-palette-primary: mat-palette( $mat-light-blue );
$hospitaler-palette-primary: mat-palette( $mat-hospitaler );
$hospitaler-palette-accent: mat-palette( $mat-hospitaler-secondary, A200, A100, A700 ); 

// create the actual theme object; we can set colors, typography, etc.
$hospitaler-theme-light: mat-light-theme((
    color: (
        primary: $hospitaler-palette-primary,
        accent: $hospitaler-palette-accent,
        warn: mat-palette( $mat-red )
    )
));

// set the main theme for the application
@include angular-material-theme( $hospitaler-theme-light );

form mat-form-field textarea.mat-input-element {
  overflow: hidden;
}

// set overrides for theme, install flex options
$flex-item-margin-default: 15px; // default margin between flex elements

.flex-container {
  display: flex;
  flex-direction: column;
  .flex-item {
    margin: $flex-item-margin-default;
    flex: 1 1 auto;
  }
}

// form styles
form {
  button.mat-button, 
  button.mat-raised-button {
    line-height: 48px;
    font-size: 16px;
  }
  mat-slide-toggle {
    margin-bottom: 16px;
  }
  div {
    &.field-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      mat-slide-toggle {
        flex: 1 0 49%
      }
    }
    &.controls {
      button {
        margin-right: 6px;
        display: inline-block;
      }
    }
  }
}

.mat-toolbar {
  .mat-toolbar-row {
    .spacer {
      flex: 1 1 auto;
    }
    button {
      &.mat-raised-button {
        margin-left: 6px;
      }
    }    
  }
}


@mixin role-container() 
{
  span {
    &.role-container {
      background-color: #eeeeee;
      display: inline-block;
      padding: 4px 12px;
      margin-right: 2px;
      border-radius: 4px;
      margin-bottom: 1px;
      margin-top: 1px;
    }
  }
}
@include role-container();


.field-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 8px;
  > div {
   display: flex;
   flex: 1 0 49%;
   flex-direction: column;
   padding: 8px;
    label {
      font-size: 0.8rem;
      font-weight: 500;
    }
    span {
      background-color: #ffffff;
      padding: 12px;
      border-bottom: 1px solid #aaaaaa;
      @include role-container();
    }
    &.full-width {
      flex: 1 0 95%;
    }
    &.half-width {
      flex: 1 0 49%;
    }
  }
}


